import Joi from "joi-browser";
import ShowroomCreate from "../ShowroomCreate";

class ShowroomEdit extends ShowroomCreate {
  schema = { ...this.schema, id: Joi.number().required() };
  title = "Editer le showroom";

  componentDidMount = () => {
    this.getCities();
    this.populateShowroom();
    console.log('this',this)
  };

  populateShowroom = async () => {
    try {
      const id = this.props.match.params.id;
      const { data: showroom } = await this.showroomHelper.findOne(
        `${this.apiPath}/${id}`
      );
      console.log("showroom", showroom);

      this.setState({ data: this.mapToViewShowroom(showroom) });
    } catch (ex) {
      console.log("ex", ex);

      if (ex.response && ex.response.status === 404) {
        return this.props.history.replace("/404");
      }
    }
  };

  doSubmit = async () => {
    console.log("Submitted");

    const id = this.props.match.params.id;
    await this.showroomHelper.update(`${this.apiPath}/${id}`, this.state.data);
    this.props.history.replace("/showrooms");
  };

  mapToViewShowroom = (showroom) => {
    return {
      id: 1,
      name: showroom.name,
      city: `/cities/${showroom.city.id}`,
      address: showroom.address,
      code: showroom.code,
      tags: showroom.tags,
      telephone: showroom.telephone,
      telephoneSecondary: showroom.telephoneSecondary,
    };
  };
}

export default ShowroomEdit;
