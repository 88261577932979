import React, {Component} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import _ from "lodash";
import Swal from "sweetalert2";

import Pagination from "../../../components/Pagination";
import Search from "../../../components/Form/Search";
import Table from "../../../components/Table";
import ApiResourceHelper from "../../../helpers/ApiResourceHelper";
import paginate from "../../../utils/paginate";
import {Col, Dropdown, Row} from "react-bootstrap";
import {FaEllipsisH, FaEye, FaTrash, FaEdit} from "react-icons/all";
import DateDisplay from "../../../components/DateDisplay";

class ShowroomList extends Component {
    state = {
        showrooms: [],
        currentPage: 1,
        pageSize: 30,
        sortColumn: {path: "name", order: "asc"},
        searchQuery: "",
    };

    apiPath = "/showrooms";

    columns = [
        {
            path: "dateSorted",
            label: "Date",
            content: (showroom) => (
                <DateDisplay date={showroom.createdAt}/>
            ),
        },
        {
            path: "name",
            label: "Nom",
        },
        {
            path: "citySorted",
            label: "Ville",
            content: (showroom => (
                <span>{showroom.city && showroom.city.name}</span>
            )),
        },
        {
            path: "tagsSorted",
            label: "tags",
            content: (showroom) => (
                <span>{showroom.tags && showroom.tags.join(" / ")}</span>
            ),
            
        },
        {
            path: "telephone",
            label: "Téléphone",
            content: (showroom => (
                <span>{showroom.telephone}{showroom.telephoneSecondary && `/${showroom.telephoneSecondary}`}</span>
            )),
        },
        {
            key: "actions",
            content: (showroom) => (
                <React.Fragment>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="transparent"
                                         className={"text-600 btn-reveal btn btn-link btn-sm"}>
                            <FaEllipsisH/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item className={"p-0"}>
                                <Link
                                    to={`/showrooms/${showroom.id}/edit`}
                                    className="btn btn-light btn-block shadow-sm text-left"
                                >
                                    <FaEdit/> Editer
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item className={"p-0"}>
                                <button
                                    className="btn btn-light btn-block shadow-sm text-left"
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Etes-vous sûr?',
                                            text: 'Vous êtes sur le point de supprimer ce showroom.',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Oui, je le supprime!',
                                            cancelButtonText: 'Non, je le garde!'
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                if (this.handleDelete(showroom)) {
                                                    Swal.fire('Showroom supprimé!', 'Le showroom a été bien supprimé.', 'success');
                                                } else {
                                                    Swal.fire('Showroom non supprimé!', 'On ne peut pas supprimer request à cause d\'une erreur système.', 'error');
                                                }
                                            } else {
                                            }
                                        });
                                    }}
                                >
                                    <FaTrash/> Supprimer
                                </button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
            ),
        }

        ,
    ]
    ;

    showroomHelper;

    constructor(props) {
        super(props);
        this.showroomHelper = new ApiResourceHelper(this.apiPath);
    }

    componentDidMount() {
        this.getShowrooms();
    }

    getShowrooms = async () => {
        const {data} = await this.showroomHelper.findAll();

        const finalShowrooms = [];

        data["hydra:member"].forEach((showroom) => {
            const date = new Date(showroom.createdAt);
            const dateSorted = `${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;
            const citySorted = showroom.city ? showroom.city.name : '';
            const tagsSorted = showroom.tags ? showroom.tags : [];

            finalShowrooms.push({
                ...showroom, dateSorted, citySorted,tagsSorted, // Adding the tags property

            })
        });

        this.setState({showrooms: finalShowrooms, total: data["hydra:totalItems"]});
    };

    handleDelete = async (showroom) => {
        const originalshowrooms = this.state.showrooms;

        const showrooms = originalshowrooms.filter((b) => b.id !== showroom.id);
        this.setState({showrooms});

        try {
            await this.showroomHelper.remove(`${this.apiPath}/${showroom.id}`);

            return true;
        } catch (ex) {
            if (ex.response && ex.response === 404)
                toast.error("This showroom has already been removed.");

            this.setState({showrooms: originalshowrooms});
            return false;
        }
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        this.setState({sortColumn});
    };

    getPagedData = () => {
        const {
            showrooms: allshowrooms,
            currentPage,
            pageSize,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = allshowrooms;

        if (searchQuery) {
            filtered = allshowrooms.filter((b) =>
                b.name.toLowerCase().startsWith(searchQuery)
                || b.address.toLowerCase().startsWith(searchQuery)
                || b.telephone.toLowerCase().startsWith(searchQuery)
                || (b.city && b.city.name.toLowerCase().startsWith(searchQuery))
            );
        }

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const showrooms = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: showrooms};
    };

    getExportedData = () => {
        const {data} = this.getPagedData();

        const exportedData = [];

        data.forEach((item, index) => {
            exportedData.push({
                nom: item.name,
            })
        });

        return exportedData;
    }

    handleSearchChange = (query) => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    render() {
        const {showrooms, total, currentPage, pageSize, sortColumn} = this.state;
        const {user} = this.props;

        console.log("user", user);

        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={showrooms}
                    exportedData={[]}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                    createLink="showrooms/create"
                    tableName={"showrooms"}
                    tableTitle={"Liste de showrooms"}
                    searchQuery={this.state.searchQuery}
                    onSearchChange={this.handleSearchChange}
                    totalCount={total}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                />
            </React.Fragment>

        );
    }
}

export default ShowroomList;
