import React from "react";
import Select from 'react-select';

const MultiSelect = ({ name, label, onChange, value, error }) => {
    const options = [
        { value: 'none', label: 'None' }, // Use "none" as the value for the "None" option
        { value: 'fiat', label: 'Fiat' },
        { value: 'jeep', label: 'Jeep' },
        { value: 'alfaromeo', label: 'Alfa Romeo' },
        { value: 'tonale', label: 'Tonale' },
        { value: 'abarth', label: 'Abarth' }
    ];

    const handleChange = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        
        if (selectedValues.includes("none")) {
            // If "None" is selected, only send "none" and disable other options
            onChange(["none"]);
        } else {
            // Otherwise, send selected values without "none"
            onChange(selectedValues);
        }
    };

    // Check if "None" is currently selected
    const isNoneSelected = value && value.includes("none");

    return (
        <div className="mt-3 mb-3">
            {label && <label htmlFor={name} className="block text-gray-700">{label}</label>}

            <Select  
                
                name={name}
                id={name}
                options={options} 
                onChange={handleChange}
                isMulti
                value={options.filter(option => value.includes(option.value))}
                isOptionDisabled={(option) => isNoneSelected && option.value !== "none"} // Disable other options if "None" is selected
            />

            {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
        </div>
    );
};

export default MultiSelect;
