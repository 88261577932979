import React, { Component } from "react";
import Joi, { errors } from "joi-browser";
import {FaSave, FaSignInAlt} from "react-icons/fa";

import Input from "./Input";
import Select from "./Select";
import Button from "./Button";
import ButtonWithoutIcon from "./ButtonWithoutIcon";
import MultiSelect from "./MultiSelect";

class Form extends Component {
  validate = () => {
    const options = {
      abortEarly: false,
    };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    console.log("joi error", error);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    console.log('state.data', this.state);

    const errors = this.validate();
    console.log("errors", errors);

    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }, callback=null) => {
    console.log("input", input);

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };

    console.log("data",data,this)

    if (input.type === "number") {
      data[input.name] = parseInt(input.value);
    } else if (input.type === "boolean") {
      data[input.name] = input.value === "true";
    } else if (input.name === "tags"){
      data[input.name] = [input.value];
    }else {
      data[input.name] = input.value;
    }

    if (input.value === 'isNull') {
      errors[input.name] = "Ce champ n'est pas valide.";
    }

    this.setState({ data, errors });
    if (callback) callback(input.value);
  };
  

  renderButton(label) {
    return (
        <Button
            type="submit"
            label={label}
            icon={FaSave}
            cssClass="btn btn-primary mt-3"
        />
    );
  }

  renderButtonWithoutIcon(label) {
    return (
        <ButtonWithoutIcon
            type="submit"
            label={label}
            cssClass="btn btn-primary-fca d-block btn-lg w-100"
        />
    );
  }

  renderInput(name, type, label, placeholder) {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        label={label}
        placeholder={placeholder}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderSelect(name, label, options, callback=null) {
    const { data, errors } = this.state;

    return (
      <Select
        value={data[name]}
        name={name}
        label={label}
        options={options}
        onChange={(e) => {
          if (callback) {
            this.handleChange(e, callback);
          } else {
            this.handleChange(e);
          }
        }}
        error={errors[name]}
      />
    );
  }

  handleMultipleChange = (name, selectedValues) => {
    const values = selectedValues.includes(null) ? [] : selectedValues; // Set to empty array if 'Choose None' is selected
    this.setState((prevState) => ({
        data: {
            ...prevState.data,
            [name]: values,  // Update the field with the selected values array
        },
    }));
    console.log("Selected Values:", values); // Check selected values
};


renderMultiSelect(name, label, options, callback = null) {
  const { data, errors } = this.state;

  return (
      <MultiSelect
          value={data[name] || []}  // Ensure value is an array for multiple select
          name={name}
          label={label}
          options={options}
          onChange={(selectedValues) => { // Pass selectedValues array directly
              this.handleMultipleChange(name, selectedValues); // Handle the change with name and values
              if (callback) callback(selectedValues);         // Call callback if it exists
          }}
          error={errors[name]} // Display any associated error
      />
  );
}


}

export default Form;
